@import url('https://fonts.googleapis.com/css2?family=Inter+Tight&display=swap');

/* RESET */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* RESET */

body {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 400;
    line-height: 1.5;

    background-color: #FFF;
    color: #000;

    width: 100%;
    height: 100%;
    overflow: hidden;
}

.nav-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: sticky;

    margin-left: 40px;
    margin-top: 30px;
    z-index: 1;
    font-size: 32px;
}

.nav-info {
    justify-self: end;
    margin-right: 40px;
}

.nav-back {
    justify-self: end;
    margin-right: 40px;
}

a {
    text-decoration: none;
    color: #000;
}

a:hover {
    border-bottom: 2px solid #000;
}

img, video{
    position: absolute;
    top: 500px;
    left: 400px;
    transform: translate(-50%, -50%) scale(0.5);
    animation: fadein 0.2s;
}

footer {
    position: fixed;
    bottom: 30px;
    margin-left: 40px;
    z-index: 1;
    font-size: 20px;
}

.info-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    margin-left: 40px;
    margin-top: 30px;
    z-index: 1;
    font-size: 32px;
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}